<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新增报损报溢管理</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex">
          <div> 
            <el-form-item label="报告日期：" prop="report_date">
              <el-date-picker
                v-model="form.report_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:300px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="!form.id?'单据编号前缀：':'单据编号：'" prop="report_no">
                <el-select v-model="form.report_no" v-if="!form.id" placeholder="请选择"
                    style="width: 100%;; margin-right: 15px;" filterable>
                    <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                        :key="index" :value="item.value"></el-option>
                </el-select>
                <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.report_no"
                    placeholder="请输入单据编号" />
            </el-form-item>
            <el-form-item label="部门：" prop="department_id">
              <el-cascader style="width:300px" v-model="form.department_id" :options="list"
                  :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                  clearable></el-cascader>
            </el-form-item>
            <el-form-item label="业务员：" prop="admin_id">
              <el-select v-model="form.admin_id" placeholder="请选择"
                  style="width: 100%; margin-right: 15px;" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in rylist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="仓库货架:" prop="warehouse_id">
              <el-select v-model="form.warehouse_id" placeholder="请选择货位" clearable 
                  style="width:300px" filterable>
                  <el-option :label="item.cargo_space_name" v-for="(item,index) in hwlist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="备注：">
                <el-input type="textarea" v-model="form.remark" placeholder="请输入描述" maxlength="100"
                    style="width:100%" />
            </el-form-item>
          </div>
        </div>

        <div class="table_Box">
          <div class="table_title">
            产品： <span @click="sele_products">选择产品</span>
          </div>
          <el-table :data="have_sele_arr" style="width: 100%">
            <el-table-column prop="product_no" label="产品编号" />
            <el-table-column prop="product_name" label="品名" />
            <el-table-column prop="model" label="型号" />
            <!-- <el-table-column label="批号" width="200">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.batch_number" @change="change($event,scope.$index)" placeholder="请选择"
                      style="width: 100%;; margin-right: 15px;" filterable>
                      <el-option :label="item.batch_number" v-for="(item,index) in (scope.row.seles || [])"
                          :key="index" :value="item.batch_number"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column> -->
            
            <el-table-column label="批号" width="150">
              <template v-slot="scope">
                <div>
                  {{scope.row.batch_number}} <a href="javascript:;" style="color: #1676e0;" @click="sele_batch(scope.$index)">选择</a>
                </div>
              </template>
            </el-table-column>
            
            
            <el-table-column label="有效期" >
              <template v-slot="scope">
                <div>
                  {{scope.row.valid_until || '长效期'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="报损数量" width="150">
              <template v-slot="scope">
                <div>
                  <el-input-number
                    v-model="scope.row.loss_num" @change="num_change(1,scope.$index)" 
                    controls-position="right"
                    :min="0"
                    size="small"
                  ></el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="报溢数量" width="150">
              <template v-slot="scope">
                <div>
                  <el-input-number
                    v-model="scope.row.overflow_num"  @change="num_change(2,scope.$index)" 
                    controls-position="right"
                    :min="0"
                    size="small"
                  ></el-input-number>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column fixed="right" label="操作">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
  <selectProducts ref="selectProducts" @after_sele="after_sele_Products" />
  <salebatch ref="salebatch" @after_sele="after_sele_batch" />
</template>

<script>
import salebatch from "@/components/seleBatch";
export default {
  name: "addBreakage",
  components: {salebatch},
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
         
      },
      rules: {
        report_date: [
          { required: true, message: "报告日期不能为空",trigger: "blur"}
        ],
        report_no: [
          { required: true, message: "编号前缀不能为空",trigger: "blur"}
        ],
        department_id: [
          { required: true, message: "部门不能为空",trigger: "blur"}
        ],
        admin_id: [
          { required: true, message: "业务员不能为空",trigger: "blur"}
        ],
        warehouse_id: [
          { required: true, message: "仓库货架不能为空",trigger: "blur"}
        ]
      },
      zjlist:[],
      list:[],
      rylist:[],
      hwlist:[],
      // 已选择产品
      have_sele_arr:[],
      phlist:[]
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }else{
          this.form.report_date=this.today_val()
      }
      this.getOptions();
      this.remoteMethod()
  },
  methods: {
      num_change(type,index){
        let item=this.have_sele_arr[index];
        if(type==1){
          if(item.loss_num){
            item.overflow_num=0
          }
        }else{
          if(item.overflow_num){
            item.loss_num=0
          }
        }
      },
      after_sele_batch(e){
          console.log(e)
          this.have_sele_arr[e.index].batch_number=e.row.batch_number;
          this.have_sele_arr[e.index].valid_until=e.row.valid_until;
          this.have_sele_arr[e.index].stock_id=e.row.id;
          // this.have_sele_arr[e.index].book_amount=e.row.stock;
      },
      sele_batch(index){
          console.log(index,this.have_sele_arr[index])
          this.$refs.salebatch.init(this.have_sele_arr[index].product_id,index);
      },
      change(e,index){
        let item=this.have_sele_arr[index];
        for(let i=0;i<item.seles.length;i++){
            if(item.seles[i].batch_number==e){
                this.have_sele_arr[index].valid_until=item.seles[i].valid_until;
                this.have_sele_arr[index].book_amount=item.seles[i].stock;
                break;
            }
        }
      },
    /** 删除按钮操作 */
    handleDelete(index) {
        const _this=this;
        this.$confirm('是否确认删除?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            _this.have_sele_arr.splice(index,1)
        })
    },
    // 选择产品
    after_sele_Products(arr){
        for(let i=0;i<arr.length;i++){
            arr[i].batch_number='';
            arr[i].valid_until=null;
            arr[i].stock_id='';
        }
        this.have_sele_arr=this.have_sele_arr.concat(arr)
        let ids=[];
        for(let i=0;i<arr.length;i++){
            ids.push(arr[i].product_id)
        }
        // this.get_phdata(ids)
    },
    sele_products(){
        let ids=[]; // 已选择的id
        for(let i=0;i<this.have_sele_arr.length;i++){
            ids.push(this.have_sele_arr[i].product_id)
        }
        this.$refs['selectProducts'].init([],0,1)
    },
    
    // 表单重置
    reset() {
      this.form = {
        report_date:this.today_val(),
        report_no:this.zjlist.length>0?this.zjlist[0].value:''
      };
      this.have_sele_arr=[];
      if(this.$refs['form']){
        this.$refs['form'].clearValidate();
      }
    },
    /** 提交按钮 */
    submitForm: function(form) {
      this.$refs[form].validate((valid)  => {
        if (valid) {
          if(form=='form'){
            let proarr=[];
            let _data={...this.form}
            if(this.have_sele_arr.length==0){
                this.$message.error('请选择产品');
                return false;
            }
            for(let i=0;i<this.have_sele_arr.length;i++){
                let _item=this.have_sele_arr[i];
                if(!_item.batch_number || (!_item.loss_num && !_item.overflow_num)){
                    this.$message.error('请完善批号及数量');
                    return false;
                }else{
                    proarr.push({
                        product_id:_item.product_id,
                        loss_num:_item.loss_num,
                        overflow_num:_item.overflow_num,
                        batch_number:_item.batch_number,
                        valid_until:_item.valid_until,
                        stock_id:_item.stock_id,
                    })
                }
            }
            // 提交
            this.is_ajax = true;
            _data.product_array=JSON.stringify(proarr);
            this.$httpPost("/backend/ReportLossOverflow/save", _data).then((res) => {
                if (res.status == 200) {
                    this.$message.success(this.id ? '更新成功' : '新增成功');
                    if(this.id){
                        this.$router.go(-1)
                    }else{
                        // this.$nextTick(()=>{
                        //     this.reset();
                        // })
                        this.$router.go(-1)
                    }
                } else {
                    this.$message.error(res.message);
                }
                this.is_ajax = false;
            }).catch((err) => {
                console.log(err);
                this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data(){
        this.$httpGet("/backend/ReportLossOverflow/read", {id:this.id}).then((res) => {
            if (res.status == 200) {
                this.form=res.data;
                let pros=res.data.report_loss_overflow_product;
                let ids=[];
                for(let i=0;i<pros.length;i++){
                    pros[i].id=pros[i].product_id;
                    pros[i].product_no=pros[i].product.product_no;
                    pros[i].product_name=pros[i].product.product_name;
                    pros[i].model=pros[i].product.model;
                    ids.push(pros[i].product_id)
                }
                this.have_sele_arr=pros;
                // this.get_phdata(ids)
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    remoteMethod(keyword){
        this.$httpGet("/backend/Warehouse/index", {size:1000000,title:keyword}).then((res) => {
            if (res.status == 200) {
                this.hwlist = res.data.data;
                // 默认选中第一个
                this.form.warehouse_id=this.hwlist.length>0?this.hwlist[0].id:''
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    get_phdata(ids){
        this.$httpGet("/backend/ReportLossOverflow/newProductBatchNumber", {
            ids: ids.join(',')
        }).then((res) => {
            if (res.status == 200) {
                let list = res.data;
                for(let i=0;i<this.have_sele_arr.length;i++){
                    let arr=[];
                    for(let k=0;k<list.length;k++){
                        if(list[k].product_id==this.have_sele_arr[i].product_id){
                            arr.push(list[k])
                        }
                    }
                    this.have_sele_arr[i].seles=arr
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '报损报溢'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(!this.id && this.zjlist.length>0){
                    this.form.report_no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
            if (res.status == 200) {
                this.list = res.data.department_list;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        
        this.$httpGet("/backend/sys.Admin/salesmanList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data.data;
                if(this.rylist.length>0 && !this.id){
                    this.form.admin_id=this.rylist[0].id
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;
  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }
  .el-table {
    margin: 30px 0;
  }
}
.top_box {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>